<template>
  <div class="row footer">
    <div class="row environments">
      <div class="row">
        <a class="nav-link" href="#" style="padding-left: 2vw; padding-right: 2vw;">
            <!-- <img class="icon" src="@/assets/text/goodStrings-inc.svg" /> -->
          <img class="icon" src="@/assets/icons/ws_logo.png"/>
          <a class="navbar-brand" href="#">
            <span class="good">Good</span><span class="strings">Strings</span>
          </a>
        </a>
      </div>
      <div class="row">
        <div class="row firsttab">
          <div class="row">
            <p class="lefttext">
              <b>Building the next generation of educated learners</b><br>
              <br><i>info@goodstrings.io</i>
            </p>
          </div>
        </div>
        <div class="row tab">
          <!-- <ul>
            <li><a href="/"><b>Home</b></a></li>
            <li><a href="https://whitestrings.io/#sms">School Management System</a></li>
            <li><a href="https://whitestrings.io/#lms">Learning Management System</a></li>
            <li><a href="https://whitestrings.io/#sma">Strings Mobile App</a></li>
            <li><a href="https://whitestrings.io/#tfme">The future of Mobile Education</a></li>
          </ul> -->
        </div>
        <div class="row tab">
          <!-- <ul>
            <li><a href="https://whitestrings.io/features"><b>Features</b></a></li>
            <li><a href="https://whitestrings.io/features#fsl">For School Leaders</a></li>
            <li><a href="https://whitestrings.io/features#ft">For Teachers</a></li>
            <li><a href="https://whitestrings.io/features#fs">For Students</a></li>
            <li><a href="https://whitestrings.io/features#fp">For Parents</a></li>
          </ul> -->
        </div>
      </div>
    </div>
    <div class="row">
      <p class="footer-p"><b>2022 GoodStrings</b>. All Rights Reserved.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .row > *, .row{
      padding-right: unset !important;
      padding-left: unset !important;
      margin-left: unset !important;
      margin-right: unset !important;
  }

  *{
    text-align: left;
    color:#fff;
  }

  .good, .strings{
    color: #fff;
  }
  .nav-link{
    color: #2f2f2f !important;
  }

  .navbar-brand > .icon {
    height: 24px;
  }
  .row > *{
    justify-content: normal !important;
  }
  .lefttext{
    text-align: left;
    font-size: 14px;
    line-height: 14px;
    margin: unset;
  }
  .footer{
    margin: 0;
    padding: 2vh 6vw !important;
    /* padding: 5vh 5vw 15vh 5vw; */
    background: #1c1c1c;
  }

  .firsttab{
    /* width: 37.3%; */
  }
  .tab{
    /* width: 27.3%; */
    /* padding-top: 5vh; */
  }

  .environments, .industries{
    /* padding: 3vh 10vw; */
  }

  .icon{
    margin-top: 0vh;
    margin-bottom: 1vh;
    width: 3em;
  }

  .row.tab > .row{
    justify-content: center;
  }

  .hrarea{
    padding-left: 35vw;
    padding-right: 35vw;
    padding-bottom: 4vh;
  }

  .row > *{
    /* padding-right: unset;
    padding-left: unset; */
    justify-content: center;
  }

  ul{
    text-align: left;
  }

  ul>li{
    text-align: left;
    list-style: none;
    font-size: 14px;
  }

  .footer-p{
    text-align: right;
    margin: 0px;
    margin-top: 5px;
  }
  @media only screen and (max-width: 1024px) {
    .nav-link{
      padding-top: 2vh;
    }
    .lefttext{
      font-size: 13px;
      font-weight: normal;
    }
    .footer-p{
      font-size: 13px;
    }
  }

</style>
