<template>
  <div class="row autom" id="lms">
    <div class="left">
      <div class="row screenshots">
        <img class="dashboard" src="@/assets/screenshots/education.jpg" />
      </div>
    </div>
    <div class="right" >
      <div class="row svg">
        <img class="icon" src="@/assets/icons/schools.svg" />
      </div>
      <div class="row">
        <a class="navbar-brand" href="#">
          <h2 class="left-title">Strings for Education</h2>
        </a>
      </div>
      <div class="row">
        <h2 class="left-desc">The all-in-one platform you'll ever need<br>for your school operations.</h2>
      </div>
      <div class="row visitBtn" >
        <a href="https://whitestrings.io/" target="_blank" id="bookNow">Visit Website</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchoolMngtSys',
  props: {
    // msg: String
  },
  data() {
    return {
      showLms: false
    }
  },
  methods: {
    viewLMS() {
      this.showLms = this.showLms ? false : true; 
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .left-title{
    font-size: 30px;
    font-family: NunitoSansLight, sans-serif;
    color: #303130;
    font-weight: 700;
  }
  .inputDemo{
    background: #fff;
    border: 1px solid #a9aba8;
    border-radius: 5px;
    width: 47%;
    margin-right: 10px;
  }
  .btn-area{
    padding-top: 5vh;
    justify-content: center;
  }
  .left-desc{
    font-size: 23px;
    font-family: NunitoSansRegular, sans-serif;
    color: #686868;
    width: 100%;
  }
  .viewFeatures{
    margin-top: 20px;
    font-size: 25px;
    font-family: NunitoSansBold, sans-serif;
    color: #2F2F2F;
    cursor: pointer;
  }
  .autom{
      margin: 0;
  }
  .right{
    width: 48vw;
    text-align: left;
    padding: 5vh 5vw 5vh 10vw;
    z-index: 2;
  }

  .left{
      width: 50vw;
  }

  .white, .strings{
      font-size: 1.5em;
  }

  .icon{
      width: auto;
      max-height: 30px;
  }

  h3{
      font-size: 1.7em;
      color: green;
      font-weight: 700;
  }

  .end{
      max-width: 31vw;
  }

  .screenshots{
      padding: 2vw;
      justify-content: right;
  }

  .screenshots > img{
      /* position: absolute; */
      width: 95%
  }
  .dashboard{
      width: 55.5vw;
      /* margin-top: 3.5vh; */
      /* right: 1vw; */
  }
  .row > ul{
    padding: 2vh 0vw 0vh 4vw;
  }
  .row > ul > li {
    list-style: none;
    font-family: NunitoSansBold, sans-serif;
    color: #818181;
    font-weight: 600;
    color:#2F2F2F;
  }

  .featuresDiv{
    min-height: 174.062px;
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  .visitBtn{
    float: left;
    padding-left: 12px;
  }

  #bookNow{
    background: #3BE03B 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #FFFFFF;
    padding: 10px 15px;
    font-size: 18px;
    text-decoration: none;
    font-weight: 500;
    position: relative;
    width: fit-content;
    bottom: 0vh;
    display: block;
    margin: auto;
    text-align: center;
    /* align-items: center; */
  }
  @media only screen and (max-width: 1024px) {
    #lms{
      display: none;
    }
  }
</style>
