<template>
  <div class="row happy">
    <div class="row">
      <h1>Our Partners</h1>
    </div>
    <div class="row environments">
      <div class="row">
        <div class="row tab">
          <div class="row">
            <img class="icon" id="aws" src="@/assets/icons/aws.svg" />
          </div>
        </div>
        <div class="row tab">
          <div class="row">
            <img class="icon" id="olpmc" src="@/assets/icons/olpmc.svg" />
          </div>
        </div>
        <div class="row tab">
          <div class="row">
            <img class="icon" id="pagibig" src="@/assets/icons/pagibig.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopNote',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .row > *, .row{
      padding-right: unset !important;
      padding-left: unset !important;
      margin-left: unset !important;
      margin-right: unset !important;
  }
  .happy{
    margin: 0;
    padding-top: 5vh; 
    padding-bottom: 5vh; 
  }
  h2{
    font-family: NunitoSansBold, sans-serif;
    color: #000;
    font-size: 35px;
    font-weight: 700;
  }
  .students{
    padding: 5vh 7vw;
  }
  .tab{
    width: 33.3%;
  }

  .environments, .industries{
    padding: 5vh 10vw;
  }

  .icon{
    width: 16vw;
    height: auto;
    max-height: 150px;
  }

  .row.tab > .row{
    justify-content: center;
  }

  .hrarea{
    padding-left: 35vw;
    padding-right: 35vw;
    padding-bottom: 4vh;
  }

  .row > *{
    /* padding-right: unset;
    padding-left: unset; */
    justify-content: center;
  }

  .cover{
    padding-bottom: 1vh;
  }

  span{
    padding: 1vh 0vw;
    font-weight: 700;
  }

  .ind > .icon{
    width: 5vw;
  }

  .ind{
    height: 6vh;
  }

  .-card{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    width: 30%;
    margin: 0;
    padding: 0;
    padding-top: 2vh;
    margin-left: 2vw;
  }
  @media only screen and (max-width: 1024px) {
    h1{
      font-size: 25px;
      font-weight: 900;
    }
    .tab{
      width: 100%;
    }
    #aws{
      width: 39vw;
      padding-bottom: 5.5vh;
    }
    #olpmc{
      width: 70vw;
      padding-bottom: 5.5vh;
    }
    #pagibig{
      width: 32vw;
    }
  }
</style>
