<template>
  <div class="row dev" id="devArea">
    <div class="row developing">
      <h1>Developing “Built to Last” Software for your Organization’s success.</h1>
    </div>
    <div class="row help">
      <h3>We develop and personalize software based on your needs with our powerful ERP System to help you grow your business.</h3>
    </div>
    <div class="develop-bg">
    </div>

    <a href="#book" id="bookNow">Book a Meeting</a>
    <!-- <div class="row people">
      <div class="left">
        <img src="@/assets/graphics/automotive.svg" />
      </div>
      <div class="middle">
        <img src="@/assets/graphics/government.svg" />
      </div>
      <div class="right">
        <img src="@/assets/graphics/student.svg" />
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Developing',
  props: { 
    // msg: String
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll () {
      if(window.scrollY > 60){
        document.getElementById("devArea").classList.add("add-top");
      }else{
        document.getElementById("devArea").classList.remove("add-top");
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .dev{
    margin: 0;
    height: 90vh;
    display: block;
  }
  .developing, .help{
    justify-content: center;
  }

  .developing > h1{
    font: normal normal bold 70px/80px SF Pro;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size: 3em;
    font-weight: 700;
    letter-spacing: 0px;
    color: #262626;
    max-width: 68vw;
    line-height: 1em;

    padding-top: 5.5vh;
    padding-bottom: 1.5vh;
  }

  .help > h3{
    /* margin-top: 3.5vh; */
    margin-bottom: 3.5vh;
    max-width: 60vw;
  }

  .left, .middle, .right{
    padding: 2px;
  }

  .people{
    justify-content: center;
    padding: 0px;
    margin: 0px;
  }

  .left{
    width: 32vw;
  }
  .middle{
    margin-top: 4vh;
    width: 32vw;
  }
  .right{
    width: 33vw;
  }
  .develop-bg {
    background: url("../../assets/graphics/handv2.jpg");
    background-repeat: no-repeat;
    z-index: -1;
    background-position: 0vw 0vw;
    background-size: 100% 60%;
    /* background-color: #16181c; */
    position: absolute;
    top: 1vw;
    height: 90vw;
    width: 100%;
    right: 0;
    left: 0;
    bottom: 0;
  }

  #bookNow{
    background: #3BE03B 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #FFFFFF;
    padding: 10px 15px;
    font-size: 18px;
    text-decoration: none;
    font-weight: 500;
    position: relative;
    width: fit-content;
    bottom: 0vh;
    display: block;
    margin: auto;
    top: 38vh;
    text-align: center;
    align-items: center;
  }
  @media only screen and (max-width: 1024px) {
    .develop-bg{
      background-position: -79vw -29vw !important;
      background-size: 265% 123% !important;
      top: 0vw;
      height: 105vh !important;
      width: 100%;
    }
    .developing{
      padding-top: 30px;
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
    }
    .developing > h1{
      font-size: 30px;
      max-width: 98vw;
      padding: 2vh 2vw;
    }
    .help > h3{
      max-width: 100%;
      font-size: 15px;
      padding: 2vh 3vw;
      padding-left: 10vw;
    }
    #bookNow{
      top: 40vh;
    }
    .add-top{
      padding-top: 42.748px;
      height: 100vh;
    }
    .add-top>.develop-bg{
      background-position: -73vw -40vw !important;
    }

    .row > * {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
</style>
