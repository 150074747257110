<template>
  <div class="row book" id="book">
      <div class="bg">
        <img src="@/assets/graphics/people.png" />
      </div>
      <div class="discNote">
          <div class="holder">
            <h1>Get <span>40%</span> discount for <br>your subscription.</h1>
            <h3>Receive exact quotations when you book<br>
            a meeting with us and our team<br>
            will assist you with your needs.</h3>
          </div>
      </div>
      <div class="signUpNow" >
        <div class="row">
            <h3>Book a Meeting Now</h3>
        </div>
        <div class="row inputs">
            <div class="row">
                <input type="text" class="input fname" :class="[(!hasfirstName && showWarning) ? 'redBorder' : '']" placeholder="First Name*" v-model="firstName">
                <input type="text" class="input lname" :class="[(!haslastName && showWarning) ? 'redBorder' : '']" placeholder="Last Name*" v-model="lastName">
            </div>
            <div class="row">
                <input type="text" class="input cname" :class="[(!hascName && showWarning) ? 'redBorder' : '']" placeholder="Company Name*" v-model="cName">
                <input type="text" class="input mobile" :class="[(!hasmobileNo && showWarning) ? 'redBorder' : '']" placeholder="Mobile Number*" v-model="mobileNo">
            </div>
            <div class="row">
                <input class="input industry" :class="[(!hasIndustry && showWarning) ? 'redBorder' : '']" placeholder="Type of Industry (Agriculture, Food Prod., etc.)*" v-model="Industry" />
            </div>
        </div>
        <div class="row consent">
            <p>
                I consent to receive communications from goodStrings containing news, updates and promotions about goodStrings and its products and services. I understand that I can withdraw my consent at anytime.
            </p>
        </div>
        <div class="row checkmark">
            <p style="color: red" v-if="showWarning">Please agree to the terms below.</p>
            <p>
                <input type="checkbox" name="" v-model="isAgree"> I agree to receive email and mobile communications from goodStrings.*
            </p>
            <p>
                By clicking submit below, you consent to allow goodStrings to store and process the personal information submitted above to provide you the content requested.
            </p>
        </div>
        <div class="row btnArea">
            <button @click="sendEmail" id="btnameeting">Book a meeting</button>
        </div>
      </div>
      <div class="bottomBg">
        <img src="@/assets/graphics/allstudents.svg" />
      </div>
  </div>
</template>

<script>
// import $ from 'jquery';
// const sgMail = require('@sendgrid/mail');
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    name: 'SchoolMngtSys',
    props: {
        msg: String
    },
    data() {
        return {
            firstName: '',
            lastName: '',
            cName: '',
            mobileNo: '',
            Industry: '',
            showWarning: false,
            isAgree: false,
            hasfirstName: true,
            haslastName: true,
            hascName: true,
            hasmobileNo: true,
            hasIndustry: true,
        }
    },
    methods: {
        async sendEmail(){
            var errors = 0;
            this.showWarning = false;
            this.hasfirstName = true;
            this.haslastName = true;
            this.hascName = true;
            this.hasmobileNo = true;
            this.hasIndustry = true;
            document.getElementById("btnameeting").disabled = true;

            if(this.firstName.trim() == ''){
                errors = 1;
                this.hasfirstName = false;
            }
            if(this.lastName.trim() == ''){
                errors = 1;
                this.haslastName = false;
            }
            if(this.cName.trim() == ''){
                errors = 1;
                this.hascName = false;
            }
            if(this.mobileNo.trim() == ''){
                errors = 1;
                this.hasmobileNo = false;
            }
            if(this.Industry.trim() == ''){
                errors = 1;
                this.hasIndustry = false;
            }
            if(this.isAgree && errors == 0){
                try {
                    var inputs = document.getElementsByTagName("INPUT");
                    for (var i = 0; i < inputs.length; i++) {
                        inputs[i].disabled = true;  
                    }
                    const response = await axios.get(`https://backend.goodstrings.io/sendemail/${this.firstName.trim()}/${this.lastName.trim()}/${this.cName.trim()}/${this.mobileNo.trim()}/${this.Industry.trim()}`);
                    if(response.status == 200){
                        var x = document.getElementById("book");
                        this.firstName = '';
                        this.lastName = '';
                        this.cName = '';
                        this.mobileNo = '';
                        this.Industry = '';

                        x.style.display = "none";
                        Swal.fire(
                            'Success!',
                            'Message Received, please wait for the response from our Customer Success Team within 24hrs.',
                            'success'
                        )
                        window.scrollTo(0, 0);
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong!',
                        })
                    }

                    for (var j = 0; j < inputs.length; j++) {
                        inputs[j].disabled = false;  
                        inputs[j].value = '';  
                    }
                } catch (error) {
                    console.error(error);
                }
            }else{
                this.showWarning = true;
            }

            this.isAgree = false;
            document.getElementById("btnameeting").disabled = false;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .row{
        margin-right: unset;
        margin-left: unset;
    }
    .redBorder{
        border-color: red !important;
    }
    .book{
        margin: 0;
        /* padding-top: 70px; */
    }
    .signUpNow > * {
        text-align: left;
    }
    .bg>img{
        width: 60vw;
        right: 0;
        position: absolute;
        z-index: -1;
    }
    .fname,
    .lname,
    .cname,
    .mobile{
        width: 47% !important;
    }
    .industry{
        width: 96% !important;
    }

    button{
        font-size: 20px; 
        font-weight: 700;
        color: #fff;
        background: #3BE03B 0% 0% no-repeat padding-box;
        border-radius: 8px;
        border: none;
        padding: 7px 15px;
    }
    .inputs{
        padding-top: 30px;
    }
    .bg{
        height: 20vh;
    }
    .bottomBg{
        height: 25vh;
    }
    .discNote{
        height: 38vh;
        background: #1a301d;
        padding: 6vh 15% 6vh 0vw;
        max-height: 313px;
    }
    .holder{
        float: right;
    }
    .holder>h1{
        font-family: NunitoSansBold, sans-serif;
        color: #fff;
        text-align: left;
    }
    .holder>h3{
        margin-top: 3vh;
        color: #fff;
        text-align: left;
    }
    .bottomBg{
        padding: 6vh 0vh 6vh 60vh;
    }
    .bottomBg>img{
        width: 60vw;
    }
    .signUpNow{
        position: absolute;
        background: #fff;
        z-index: 1;
        width: 35vw;
        margin-top: 5vh;
        left: 10%;
        padding: 4vh 2vw 4vh 2vw;
        border: 1px solid #707070;
        border-radius: 10px;
        height: auto;
        box-shadow: 5px 5px 25px #00000029;
    }
    .signUpNow > .row > h3{
        font-family: NunitoSansBold, sans-serif;
    }

    .consent{
        margin-top: 15px;
    }
    a{
        font-family: NunitoSansBold, sans-serif;
        margin-top: 1vh;
        padding: 10px 20px;
        margin-left: 11px;
        width: auto;
        font-size: 15px;
        cursor: pointer;
        color: #fff !important;
        background: #f43ba9;
        border-radius: 5px;
    }
    .input, .selectCountry, .selectSchool {
        border:none;
        background-image:none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border: 1px solid #c8c8c8;
        border-radius: 6px;
        padding: 5px 10px;
        font-size: 15px;

    }
    .input , .selectCountry{
        width: 40%;
        margin-right: 10px;
        margin-top: 10px;
    }
    .selectSchool{
        width: 50%;
        margin-top: 10px;
    }
    p{
        font-family: NunitoSansBold, sans-serif;
        font-size: 12px;
        font-weight: 600;
    }
    /* #frame { width: 800px; height: 620px;} */
    #frame {
        -ms-zoom: 1;
        -moz-transform: scale(1);
        -moz-transform-origin: 0 0;
        -o-transform: scale(1);
        -o-transform-origin: 0 0;
        -webkit-transform: scale(1);
        -webkit-transform-origin: 0 0;
    }

    @media only screen and (max-width: 1024px) {

        .bg, .bottomBg{
            display: none;
        }
        .signUpNow{
            left: 6vw;
            margin-top: -4vh;
            width: 88vw;
            box-shadow: 5px 5px 25px #00000029;
            border-radius: 5px;
            border: none;
        }
        .discNote{
            height: unset;
            position: unset;
            display: block;
            padding-top: 80vh;
            padding-right: 0px;
        }
        .holder{
            float: unset;
            padding-left: 6vw;
            padding-right: 6vw;
        }
        .holder>h1, .holder>h3{
            text-align: center;
            font-weight: 600;
        }
        .holder>h1{
            font-size: 25px;
        }
        .holder>h1>span{
            font-size: 45px;
        }
        .holder>h3{
            font-size: 15px;
        }
        br{
            display: none;
        }
        .fname,
        .lname,
        .cname,
        .mobile{
            width: 46% !important;
        }
        .discNote>.holder{
            display: none;
        }
        .discNote{
            padding-top: 70vh;
        }
        .btnArea{
            justify-content: center;
        }
        button{
            width: auto;
        }
    }
</style>
