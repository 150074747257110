<template>
  <div class="row let">
    <div class="row">
      <h3><span>good<b>Strings</b> </span><b> assists your business in modernizing operations, transforming and enhancing community-wide engagement across a <span><i>hyperconnected</i></span> digital landscape.</b></h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopNote',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .row > *, .row{
      padding-right: unset !important;
      padding-left: unset !important;
      margin-left: unset !important;
      margin-right: unset !important;
  }
  *{
    color: #262626;
  }

  span, span > i{
    color: #fff;
  }

  a{
    text-decoration: none;
  }
  .let{
    background: transparent linear-gradient(254deg, #24DA20 0%, #65E965 51%, #1FC61C 100%) 0% 0% no-repeat padding-box;
    opacity: 0.9;
    padding: 10vh 15vh;
    margin: 0;
  }

  h3{
    font-size: 2em;
  }

  h5{
    font-size: .7em;
  }

  .btn-area{
    justify-content: center;
  }

  a{
    margin-top: 1vh;
    padding: .5vh 2vh;
    border: 2px solid #fff;
    border-radius: 5px;
    width: auto;
    font-weight: 700;
    font-size: 12px;
    cursor: pointer;
  }
  @media only screen and (max-width: 1024px) {
    .let{
      padding: 5vh 3vh !important;
    }
    h3, b, span, i{
      font-size: 13px;
    }
  }
</style>
