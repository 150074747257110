<template>
  <div class="row let">
    <div class="row">
      <h2>Discover what we can do for You.</h2>
    </div>
    <div class="row">
      <h3>Learn more about our services with Strings ERP Platform.</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopNote',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  *{
    color: #000;
  }

  a{
    text-decoration: none;
  }
  .let{
    background: #fff;
    padding: 5vh 0vh;
    margin: 0;
  }

  h2{
    font-size: 3em;
    font-weight: 700;
  }

  h3{
    font-size: 1.7em;
  }

  .btn-area{
    justify-content: center;
  }

  a{
    margin-top: 1vh;
    padding: .5vh 2vh;
    border: 2px solid #fff;
    border-radius: 5px;
    width: auto;
    font-weight: 700;
    font-size: 12px;
    cursor: pointer;
  }
  @media only screen and (max-width: 1024px) {
    .let{
      justify-content: center;
    }
    h2{
      font-size: 25px;
      padding: 0px 10%;
    }
    h3{
      font-size: 15px;
      padding: 0px 20%;
    }
  }
</style>
