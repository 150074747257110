<template>
  <div class="row main-nav" id="main-nav">
    <div id="mobile-nav">
      <a class="nav-link" href="#" style="padding: 0px; padding-left: 2vw; padding-right: 2vw;">
            <!-- <img class="icon" src="@/assets/text/goodStrings-inc.svg" /> -->
        <img class="icon" src="@/assets/icons/ws_logo.png" style="height: 33px;"/>
        <a class="navbar-brand" href="#">
          <span class="good">good</span><span class="strings">Strings</span>
        </a>
      </a>
    </div>
    <nav class="navbar navbar-expand-lg">
      <!-- <a class="navbar-brand" href="#">
        <span class="good">Good</span><span class="strings">Strings</span>
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button> -->
      <div class="collapse navbar-collapse" style="display: block">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" href="#" style="padding: 0px; padding-left: 2vw; padding-right: 2vw;">
              <!-- <img class="icon" src="@/assets/text/goodStrings-inc.svg" /> -->
              <img class="icon" src="@/assets/icons/ws_logo.png" style="height: 33px;"/>
              <a class="navbar-brand" href="#">&nbsp;
                <span class="good">good</span><span class="strings">Strings</span>
              </a>
            </a>
          </li>
          <li class="vh nav-item dropdown">
            <a class="nav-link active" href="#" >
              Home
            </a>
          </li>
          <li class="vh nav-item">
            <a class="nav-link" href="#" >
              Reach to your Community
            </a>
          </li>
          <li class="vh nav-item">
            <a class="nav-link" href="#" >
              Services
            </a>
          </li>
          <li class="vh nav-item">
            <a class="nav-link" href="#" >
              goodStrings for business
            </a>
          </li>
          <li class="vh nav-item">
            <a class="nav-link" href="#" >
              About Us
            </a>
          </li>
          <!-- <li class="nav-item dropdown">
            <a class="nav-link" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              About Us&nbsp;<i class="fas fa-angle-down angle-down"></i> 
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="#">Action</a></li>
            </ul>
          </li>

          <li class="nav-item">
            <a class="nav-link" href="https://whitestrings.io/book">Book a Meeting Now</a>
          </li> -->
        </ul>
      </div>
      <!-- <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <a class="nav-link" href="#">Contact Us &nbsp;<img class="icon" src="@/assets/icons/search.svg" /> </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#"><img class="icon" src="@/assets/icons/account.svg" /> My Account</a>
          </li>
        </ul>
      </div> -->
    </nav>
  </div>
</template>

<script>

export default {
  name: 'Header',
  props: {
    // msg: String
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll () {
      // console.log(window.scrollY)
      if(window.scrollY > 60){
        document.getElementById("main-nav").classList.add("nav-fixed");
      }else{
        document.getElementById("main-nav").classList.remove("nav-fixed");
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .main-nav{
    padding-left: 6vw;
    padding-right: 6vw;
    margin: 0;
    z-index: 3;
    background: #fff !important;
  }
  .nav-fixed{
    position: fixed;
    z-index: 3;
    width: 100%;
    box-shadow: 0 0 10px rgb(0 0 0 / 30%);
  }

  #navbarDropdown > svg{
      font-size: 9px;
  }

  #navbarNav{
    justify-content: right;
  }

  .navbar-nav{
    text-align: center !important;
    justify-content: center !important;
  }

  .angle-down{
      color: green;
  }

  .nav-item .active{
      font-weight: 700;
  }
  .nav-item{
      font-size: 11px;
      padding-left: 1.5vw;
      padding-right: 1.5vw;
      letter-spacing: 0px;
      color: #4E4A4A;
  }

  .nav-link{
    color: #2f2f2f !important;
  }

  .nav-link > .icon {
      height: 15px;
  }
  .navbar-brand > .icon {
      height: 24px;
  }

  #mobile-nav{
    display: none;
  }

  @media only screen and (max-width: 1024px) {
    .navbar-expand-lg{
      display: none;
    }
    #mobile-nav{
      display: block;
      padding: 2vh;
    }
    #main-nav{
      padding: 0;
    }
    .nav-link{
      float: left;
    }
    .navbar-brand{
      padding-top: 4vh;
      margin-left: 2vw;
    }
    .navbar-brand>span{
      color: #2f2f2f;
    }
    .icon{
      height: 36px;
    }
    /* .nav-fixed{
      display: none;
    } */
    /* .row{
      display: block;
    } */
  }
  .vh{
    visibility: hidden;
  }
</style>
