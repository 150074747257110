<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <h1>setup of home</h1> -->
    <!-- <TopNote/>  -->
    <Developing/> 
    <Discover/> 
    <Carousel/> 
    <StringsForEduc/> 
    <StringsForBs/> 
    <StringsForTest/> 
    <StringsForCom/> 
    <StringsForComp/> 
    <GsAssist/> 
    <OurPartners/> 
    <Book/> 
  </div>
</template>

<script>
// @ is an alias to /src
// import TopNote from '@/components/TopNote.vue'
import Developing from '@/components/home/Developing.vue'
import Discover from '@/components/home/Discover.vue'
import Carousel from '@/components/home/Carousel.vue'
import StringsForEduc from '@/components/home/StringsForEduc.vue'
import StringsForBs from '@/components/home/StringsForBs.vue'
import StringsForTest from '@/components/home/StringsForTest.vue'
import StringsForCom from '@/components/home/StringsForCom.vue'
import StringsForComp from '@/components/home/StringsForComp.vue'
import GsAssist from '@/components/home/GsAssist.vue'
import OurPartners from '@/components/home/OurPartners.vue'
import Book from '@/components/home/Book.vue'

export default {
  name: 'Home',
  components: {
    // TopNote,
    Developing,
    Discover,
    Carousel,
    StringsForEduc,
    StringsForBs,
    StringsForTest,
    StringsForCom,
    StringsForComp,
    GsAssist,
    OurPartners,
    Book
  }
}
</script>
