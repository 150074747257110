<!-- Flickity HTML init -->
<template>
    <div class="row carousel">
        <div class="gallery js-flickity" data-flickity-options='{ "wrapAround": true }'>
            <div class="gallery-cell">
                <img class="cell-main-img" src="@/assets/screenshots/mobile-education.png" />
                <div class="gallery-desc">
                    <div class="desc-item">
                        <img class="icon" src="@/assets/icons/schools.svg" />
                    </div>
                    <div class="desc-item">
                        <h1>Strings for Education</h1>
                    </div>
                    <div class="desc-item">
                        <h2>The all-in-one platform you'll ever need for your school operations.</h2>
                    </div>
                    <div class="desc-item " >
                        <a href="https://whitestrings.io" id="bookNow">Visit Website</a>
                    </div>
                </div>
            </div>
            <div class="gallery-cell">
                <img class="cell-main-img" src="@/assets/screenshots/mobile-business.png" />
                <div class="gallery-desc">
                    <div class="desc-item">
                        <img class="icon" src="@/assets/icons/business.svg" />
                    </div>
                    <div class="desc-item">
                        <h1>Strings for business:<br>Software Development</h1>
                    </div>
                    <div class="desc-item">
                        <h2>An ERP system tailor-fitted to your company that helps you eliminate the traditional processes you currently have in your organization. This Cloud- based System also gives you the flexibility to work anywhere 24/7.</h2>
                    </div>
                    <div class="desc-item " >
                        <h3 class="viewFeatures" @click="viewBusiness">
                            <u><b>View our Services</b> <span v-if="!showBusiness">+</span><span v-else>-</span></u>
                        </h3>
                    </div>
                    <div class="desc-item featuresDiv" >
                        <Transition>
                        <ul v-if="showBusiness">
                            <li>Software Consultation Services</li>
                            <li>Software Development Outsourcing</li>
                            <li>Legacy Software Modernization</li>
                            <li>Cloud Application and Consultancy</li>
                            <li>API Integrations</li>
                            <li>Custom Analytics Development</li>
                        </ul>
                        </Transition>
                    </div>
                </div>
            </div>
            <div class="gallery-cell">
                <img class="cell-main-img" src="@/assets/screenshots/mobile-security.png" />
                <div class="gallery-desc">
                    <div class="desc-item">
                        <img class="icon" src="@/assets/icons/testing.svg" />
                    </div>
                    <div class="desc-item">
                        <h1>Strings for Testing:<br>QA and Security</h1>
                    </div>
                    <div class="desc-item">
                        <h2>We help you find critical vulnerabilities and assist you to improve your processes to stay on track with your development timeline.</h2>
                    </div>
                    <div class="desc-item " >
                        <h3 class="viewFeatures" @click="viewSecurity">
                            <u><b>View our Services</b> <span v-if="!showSecurity">+</span><span v-else>-</span></u>
                        </h3>
                    </div>
                    <div class="desc-item featuresDiv" >
                        <Transition>
                        <ul v-if="showSecurity">
                            <li>QA Outsourcing Services</li>
                            <li>Security Testing</li>
                            <li>QA and Security Consultancy</li>
                            <li>Cloud Performance Testing</li>
                        </ul>
                        </Transition>
                    </div>
                </div>
            </div>
            <div class="gallery-cell">
                <img class="cell-main-img" src="@/assets/screenshots/mobile-communities.png" />
                <div class="gallery-desc">
                    <div class="desc-item">
                        <img class="icon" src="@/assets/icons/communities.svg" />
                    </div>
                    <div class="desc-item">
                        <h1>Strings for Communities:<br>Website and CRM Development</h1>
                    </div>
                    <div class="desc-item">
                        <h2>Our website development helps your business connect to a wider audience giving your customer or members more access to new information and updates with your products and services.</h2>
                    </div>
                    <div class="desc-item " >
                        <h3 class="viewFeatures" @click="viewCommunity">
                            <u><b>View our Services</b> <span v-if="!showCommunity">+</span><span v-else>-</span></u>
                        </h3>
                    </div>
                    <div class="desc-item featuresDiv" >
                        <Transition>
                        <ul v-if="showCommunity">
                            <li>Website Design</li>
                            <li>Website Development Consultancy</li>
                            <li>API & 3rd Party Integrations</li>
                            <li>CRM Development</li>
                        </ul>
                        </Transition>
                    </div>
                </div>
            </div>
            <div class="gallery-cell">
                <img class="cell-main-img" src="@/assets/screenshots/mobile-compliance.png" />
                <div class="gallery-desc">
                    <div class="desc-item">
                        <img class="icon" src="@/assets/icons/compliance.svg" />
                    </div>
                    <div class="desc-item">
                        <h1>Strings for Compliance:<br>Data Privacy Consultancy</h1>
                    </div>
                    <div class="desc-item">
                        <h2>We offer data privacy services to your business to make sure that you are compliant and secured with your data 24/7.</h2>
                    </div>
                    <div class="desc-item " >
                        <h3 class="viewFeatures" @click="viewCompliance">
                            <u><b>View our Services</b> <span v-if="!showCompliance">+</span><span v-else>-</span></u>
                        </h3>
                    </div>
                    <div class="desc-item featuresDiv" >
                        <Transition>
                        <ul v-if="showCompliance">
                            <li>Software Penetration and Vulnerability Testing</li>
                            <li>Data Privacy Compliance Consultancy</li>
                            <li>Cloud Testing and Security</li>
                        </ul>
                        </Transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Carousel',
  props: {
    // msg: String
  },
  data() {
    return {
      showBusiness: false,
      showSecurity: false,
      showCommunity: false,
      showCompliance: false,
    }
  },
  methods: {
    viewBusiness() {
      this.showBusiness = this.showBusiness ? false : true; 
    },
    viewSecurity() {
      this.showSecurity = this.showSecurity ? false : true; 
    },
    viewCommunity() {
      this.showCommunity = this.showCommunity ? false : true; 
    },
    viewCompliance() {
      this.showCompliance = this.showCompliance ? false : true; 
    },
  }
}
</script>

<style scoped>
    .row > *, .row{
        padding-right: unset !important;
        padding-left: unset !important;
        margin-left: unset !important;
        margin-right: unset !important;
    }
    .carousel{
        display: none;
    }

    @media only screen and (max-width: 1024px) {
      .carousel{
        display: block;
      }
    }
</style>

<style>
    .carousel{
        padding: 0px;
        margin: 0;
        height: 78vh;
        overflow: hidden;
        width: 100%;
    }
    .gallery {
        background: #fff;
        height: 188px;
        padding-right: unset !important;
        padding-left: unset !important;
        margin-left: unset !important;
        margin-right: unset !important;
    }
    .gallery-cell {
        width: 76%;
        height: 188px;
        margin-right: 30px;
        /* background: #8C8; */
        counter-increment: gallery-cell;
        box-shadow: -5px 5px 25px #00000029;
    }

    /* cell number */
    .gallery-cell:before {
        display: block;
        text-align: center;
        /* content: counter(gallery-cell); */
        line-height: 200px;
        font-size: 80px;
        color: white;
    }
    .flickity-page-dots{
        left: 0%;
        width: 100%;
        bottom: -37px;
    }
    .flickity-page-dots .dot{
        background: #fff;
        border: 1px solid #707070;
        width: 13.5px;
        height: 13.5px;
    }
    .flickity-page-dots .dot.is-selected {
        background: #3BE03B;
    }
    .flickity-button{
        visibility: hidden;
    }
    .cell-main-img{
        width: 100%;
        height: 188px;
    }
    .flickity-viewport{
        overflow: unset;
        overflow-x: clip;
    }
    .gallery-desc{
        position: absolute;
        /* width: 120%;
        left: -10%; */
        padding: 10px 0px;
    }
    .gallery-cell>.gallery-desc{
        opacity: 0;
        transition: 2s;
    }
    .is-selected>.gallery-desc{
        opacity: 1;
    }
    .gallery-desc>.desc-item{
        width: 100%;
        padding: 0px;
    }
    .desc-item>.icon{
        margin-top: 40px;
        margin-bottom: 10px;
        height: 23px;
    }
    .desc-item>h1{
        font-size: 20px;
        font-weight: 600;
    }
    .desc-item>h2{
        font-size: 13px;
        color: #707070;
    }
    .desc-item>.viewFeatures{
        text-align: left;
    }
    .desc-item>.viewFeatures>u{
        font-size: 13px;
        font-weight: 700;
        color: #2F2F2F;
    }
    .desc-item>ul{
        list-style: none;
        text-align: left;
        font-size: 13px;
        font-weight: 600;
    }

    #bookNow{
        background: #3BE03B 0% 0% no-repeat padding-box;
        border-radius: 5px;
        color: #FFFFFF;
        padding: 5px 10px;
        font-size: 13px;
        text-decoration: none;
        font-weight: 500;
        position: relative;
        width: fit-content;
        bottom: 0vh;
        display: block;
        margin: auto;
        text-align: center;
        align-items: center;
    }
</style>